// 
// buttons.scss
//

.btn {
    color: $white;

    .mdi {
        &:before {
            margin-top: -1px;
        }
    }
}

.btn-rounded {
    border-radius: 2em;
}

//
// light button
// 
.btn-light,.btn-white {
    color: $gray-900;
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;
    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }
    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    } // No need for an active state here
} 
//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

// 
// Button Extra Small Size
// 

// 
// Button Extra Small Size
// 

.btn-xs {
    padding: .2rem .6rem;
    font-size: .75rem;
    border-radius: .15rem;
}

// 
// Button labels
//

.btn-label {
    margin: -.55rem .9rem -.55rem -.9rem;
    padding: .6rem .9rem;
    background-color: rgba($gray-900,0.1);
}

.btn-label-right {
    margin: (-$btn-padding-y) (-$btn-padding-x) (-$btn-padding-y) ($btn-padding-x);
    padding: ($btn-padding-y) ($btn-padding-x);
    background-color: rgba($gray-900,0.1);
    display: inline-block;
}


// btn bordered

@each $color, $value in $theme-colors {
    .btn-bordered-#{$color} {
        border-bottom: 2px solid darken($value, 7.5%);
    }
}


// btn lighten

@each $color, $value in $theme-colors {
    .btn-lighten-#{$color} {
        border: 1px solid rgba($value, 0.2) !important;
        background-color: rgba($value, 0.25) !important;
        color: $value !important;

        &:focus {
            box-shadow: 0 0 0 $btn-focus-width rgba($value, .3);
        }
    }
}

/* Social Buttons */

.btn-facebook {
    color: $white !important;
    background-color: #3b5998 !important;
}
.btn-twitter {
    color: $white !important;
    background-color: #00aced !important;
}
.btn-googleplus {
    color: $white !important;
    background-color: #dd4b39 !important;
}

// file upload

.fileupload {
    overflow: hidden;
    position: relative;
    input.upload {
      cursor: pointer;
      filter: alpha(opacity=0);
      font-size: 20px;
      margin: 0;
      opacity: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }