// 
// progress.scss
//

// Custom height

// Progress height small
.progress-sm {
  height: 5px;
}


// Progress height medium
.progress-md {
  height: 8px;
}


// Progress height large
.progress-lg {
  height: 12px;
}

// Progress height extra large
.progress-xl {
  height: 15px;
}

// Progress height dubble extra large
.progress-xxl {
  height: 25px;
}


// custom progress bars

@each $color,
$value in $theme-colors {
    .progress-bar-alt-#{$color} {
        background-color: rgba(($value), 0.2);
    }
}

// Progress animated

.progress-animated {
  animation-duration: 5s;
  animation-name: animationProgress;
  transition: 5s all;
}

/* Progressbar Animated */
@keyframes animationProgress {
  from {
    width:0;
  }
}

// Progress with percentage

.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px;

  .progress {
    width: 80%;
    float: left;
    margin-top: 8px;
  }

  .progress-value {
    width: 20%;
    float: right;
    text-align: right;
    line-height: 20px;
  }
}